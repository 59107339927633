//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$font-family-sans-serif: 'Noto Sans', Helvetica, Arial, serif !default;
$font-family-monospace: 'Noto Sans', Helvetica, Arial, serif !default;

$primary: #433cd8;
$secondary: #433cd8;
$link-color: #302f4b;
$hover-color: #4da6f9

